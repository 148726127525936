<template>
    <div>
        <van-nav-bar title="在线签到" left-arrow @click-left="onClickLeft" />
        <div class="welcome-div">
            感谢您莅临“2024年陕西省职业教育活动周”会议现场。
        </div>

        <div v-if="seatNumber" class="welcome-div">您的座位号是：</div>
        <div v-else class="welcome-div">
            输入姓名进行确认签到，签到成功后会为您显示座位号。
        </div>

        <div v-if="seatNumber" style="text-align: center">
            <van-tag mark type="success" class="mark">{{ seatNumber }}</van-tag>
        </div>

        <div v-else style="text-align: center">
            <van-field v-model="signName" label="姓名" placeholder="请填写姓名" maxlength="11" required>
                <van-button slot="button" type="primary" size="middle" plain @click="handleConfirm">
                    确认
                </van-button>
            </van-field>
            <div v-if="list.length > 1">
                <div class="welcome-div" style="color:#ff0000;">
                    您的姓名重复，请选择您的报名信息
                </div>
                <van-radio-group v-model="telPhone">
                    <van-cell-group>
                        <van-cell v-for="(item, index) in list" :key="index" clickable
                            @click="telPhone = item.telPhone">
                            <template slot="title">
                                <div style="display: flex;">
                                    <span>{{ index + 1 }}.</span>
                                    <span class="margin-right5">{{ item.department }}</span>
                                </div>
                                <div style="display: flex;font-size: 14px; margin-top: 10px;">
                                    <span class="margin-right5 vertical-middle">{{ item.position }}</span>
                                    <span class="margin-right5 vertical-middle">{{ item.phoneDisplay }}</span>
                                </div>
                            </template>
                            <van-radio slot="right-icon" :name="item.telPhone" />
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <van-button plain type="primary" style="margin: 10px auto" @click="handleSign">确认签到</van-button>
            </div>

        </div>
        <van-divider style="color: #6e6e6e; border-color: #1989fa; padding-top: 25px;font-size: 16px;">
            座位图(点击图片，可进行预览缩放)
        </van-divider>
        <van-image width="100%" src="/zuoweitu.jpg"  @click="showPreview=true"/>
        <van-image-preview v-model="showPreview" :images="['/zuoweitu.jpg']"   :maxZoom="5" />

    </div>
</template>

<script>
import { Toast, Dialog } from "vant-green";
import $valid from "@/utils/valid";
import { fetchListByName, fetchSignMeeting } from "@/api/meeting";
export default {
    data() {
        return {
            showPreview:false,
            signName: "",
            seatNumber: "",
            list: [],
            telPhone: null,
        };
    },
    methods: {
        onClickLeft() {
            this.$router.push("/");
        },
        /**确认姓名 */
        handleConfirm() {
            this.telPhone = null
            fetchListByName({ name: this.signName }).then((res) => {
                if (res.code !== 0) return;
                const list = res.data;
                if (list.length === 0) {
                    Dialog.alert({
                        title: '签到失败',
                        message: '您填写的姓名未查询到报名信息'.concat('<br/>技术支持：刘老师<br/>电话：17791243312'),
                        confirmButtonText: '我知道了'
                    }).then(() => {
                        return;
                    });
                }
                else if (list.length === 1) {
                    //只有一条记录，即姓名未重复
                    //进行签到
                    this.telPhone = list[0].telPhone
                    this.handleSign()
                } else {
                    //多条记录，显示报名信息进行选择
                    this.list = list.map(item => {
                        item.phoneDisplay = item.telPhone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
                        return item
                    })
                }
            });
        },
        /**确认签到 */
        handleSign() {
            if (!$valid.isPhone(this.telPhone)) {
                Toast(`请先选择您的报名信息`);
                return;
            }
            fetchSignMeeting({ telPhone: this.telPhone }).then((res1) => {
                if (res1.code == 0) {
                    this.seatNumber = res1.data || '请与会务组联系'
                }
            });
        },
    },
};
</script>

<style scoped>
.welcome-div {
    text-indent: 2em;
    line-height: 30px;
    margin: 10px;
}

.mark {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
</style>
